import React from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';

import Member from './Member';

const Wrapper = styled(W)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 21px;
  padding-bottom: 75px;

  @media only screen and (max-width: 810px) {
    display: block;
    padding-bottom: 30px;
  }
`;

const Grid = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, i) => {
        return <Member key={i} data={item} />;
      })}
    </Wrapper>
  );
};

export default Grid;
