import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../../helpers/context';

import Template from '../../templates/team';

const ContactPage = ({ data }) => {
  const team = data.med.pageTeam;
  const contact = data.med.pageContact;
  
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          contextData={data}
          contextSet={set}
          langTo={'/team'}
          data={team}
          contact={contact}
        />
      )}
    </ContextConsumer>
  );
};

export const pageTeamEnQuery = graphql`
  {
    med {
      pageTeam(where: { id: "ckjtu33yw06ga0a53nvjxlejq" }, , locales: en) {
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        intro
        teamMembers {
          localizations(includeCurrent: true, locales: [pl_PL]) {
            image {
              handle
              height
              width
            }
          }
          name
          position
          image {
            handle
            height
            width
          }
        }
      }
      pageContact(where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }, locales: en) {
        heading1
        email
        heading2
        address
        mapsUrl
      }
    }
  }
`;

export default ContactPage;
