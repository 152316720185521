import React from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';
import H from '../styled/Heading';
import P from '../styled/Paragraph';

const Wrapper = styled(W)`
  margin-bottom: 30px;

  @media only screen and (max-width: 810px) {
    margin-bottom: 16px;
  }
`;

const Inner = styled.div`
  padding-bottom: 24px;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};

  @media only screen and (max-width: 810px) {
    padding-top: 10px;
    padding-bottom: 14px;
    border-width: 1px;
  }
`;

const Heading = styled(H)``;

const Paragraph = styled(P)`
  max-width: 822px;
`;

const Top = ({ data }) => {
  return (
    <Wrapper>
      <Inner>
        <Heading size={1}>{data.title}</Heading>
        <Paragraph>{data.intro}</Paragraph>
      </Inner>
    </Wrapper>
  );
};

export default Top;
