import React from 'react';
import styled from 'styled-components';
import GraphImg from 'graphcms-image';

import H from '../styled/Heading';

const Wrapper = styled.div`
  padding-bottom: 30px;

  @media only screen and (max-width: 810px) {
    padding-bottom: 15px;
  }
`;

const Image = styled.div`
  max-height: calc((50vw - 35px) * 0.64);
  overflow: hidden;
  margin-bottom: 13px;

  @media only screen and (max-width: 810px) {
    max-height: calc((100vw - 28px) * 0.64);
  }
`;

const Name = styled(H)``;

const Position = styled(H)`
  font-family: sans-serif;
`;

const Member = ({ data }) => {
  return (
    <Wrapper>
      {data.localizations &&
        data.localizations[0] &&
        data.localizations[0].image && (
          <Image>
            <GraphImg image={data.localizations[0].image} maxWidth={600} />
          </Image>
        )}
      <Name size={3}>{data.name}</Name>
      <Position size={3} as="div">
        {data.position}
      </Position>
    </Wrapper>
  );
};

export default Member;
