import React, { useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';

import W from '../components/styled/Wrapper';

import Top from '../components/team/Top';
import Grid from '../components/team/Grid';

import Footer from '../components/layout/Footer';

const Home = ({ data, contextSet, langTo }) => {
  useEffect(() => {
    contextSet({ langTo: langTo });
  }, []);

  return (
    <React.Fragment>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      <Top data={data} />
      <Grid data={data.teamMembers} />
      <W>
        <Footer />
      </W>
    </React.Fragment>
  );
};

export default Home;
